import React,{useState,useEffect} from "react";
import { Link,useLocation } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { Modal } from "bootstrap";
const CenterContent = () => {
    

    let url = window.location.href;
    let path = new URL(url).pathname;
    let index = path.split("/");

    index = index[index.length-1];
    let card_index = index;
    index = index.replace(/-/g, ' ');

    const [card_detail,setcard_detail] = useState([]);

    useEffect(()=>{
        const newItem ={
            function_name    :"get_ecard",
            index:index
        };
        axios.post(process.env.REACT_APP_SERVER_URL,newItem)
            .then(res=>{
                setcard_detail(res.data);
            })
            .catch(error=>{
                console.log(error);
            })
            ;   
    });

    

    function toggleModal()
    {
        let myModal = new Modal(document.getElementById('scaner'));
        myModal.show();           
    }
    function HideModal(e)
    {
        e.preventDefault();
        let myModal = new Modal(document.getElementById('scaner'));
        myModal.hide(); 
    }
    return (
        <section className="card-show">
            <div className="container">
                <div className="row py-3">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 mx-auto">
                        <div className="row pb-4  mb-5">
                            <div className="col-sm-6 ml-auto text-right">
                                <div className="btns-holder pb-3">
                                    <a href={"#"}  title="Download VCard" className="downloadVcard btn btn-light btn-sm">
                                        Click to Save
                                        <i aria-hidden="true" className="mx-2 fas fa-id-card"></i>
                                    </a>
                                    <a title="Share VCard" id="socialShare" className="btn btn-light btn-sm">
                                        Share
                                        <i aria-hidden="true" className="mx-2 fas fa-share-alt fa-1x"></i>
                                    </a>
                                    <a onClick={(e)=>toggleModal(e)} className="btn btn-light btn-sm">
                                        Scan
                                        <i aria-hidden="true" className="mx-2 fas fa-qrcode fa-1x"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="box pt-0 mt-5" style={{position:"relative"}}>
                            <div style={{position: "absolute", top: "-70px", left: "0px", right: "0px"}}>
                                <img src={(card_detail.staff_img)?card_detail?.staff_img:"https://sehalife.com/assets/images/sl.png"} 
                                width="140" height="140" style={{borderRadius: "100%"}}/>
                            </div>
                            <div className="box-inner-2" style={{background: "linear-gradient(90deg, #014E76 0%, #6FC3D4 127.93%, rgba(1, 78, 118, 0) 201.42%)"}}>
                                <div className="pt-5 pb-5" 
                                        style={{background: "url(&quot;&quot;) center center / cover no-repeat"}}>
                                    <div style={{textShadow: "rgb(0, 0, 0) 7px 1px 1rem"}}>
                                        <h2 className="name mt-5">{(index=="agnieszka montgomery")?"Dr Agnieszka Montgomery":card_detail?.staff_name}</h2> 
                                        <h3 className="company">{card_detail?.hosp_name}</h3>
                                    </div>
                                </div> 
                                <div className="row border border-right-0 border-left-0">
                                    <div className="col-4 border-right d-flex flex-column justify-content-center align-items-center pt-2 pb-2">
                                        <a href={"tel:"+card_detail?.landline} style={{color: "rgb(255, 255, 255)"}}>
                                            <i aria-hidden="true" className="fas fa-phone-alt"></i> 
                                            <p className="mb-0">Call</p>
                                        </a>
                                    </div> 
                                    <div className="col-4 border-right d-flex flex-column justify-content-center align-items-center pt-2 pb-2">
                                        <a href={"mailto:"+card_detail?.email} target="_top" style={{color: "rgb(255, 255, 255)"}}>
                                            <i aria-hidden="true" className="fas fa-paper-plane"></i> 
                                            <p className="mb-0">Email</p>
                                        </a>
                                    </div> 
                                    <div className="col-4 d-flex flex-column justify-content-center align-items-center pt-2 pb-2">
                                        <a href={card_detail?.gmap} target="_blank" 
                                        style={{color: "rgb(255, 255, 255)"}}>
                                            <i aria-hidden="true" className="fas fa-map-marker-alt"></i> 
                                            <p className="mb-0">Direction</p>
                                        </a>
                                    </div>
                                </div>
                            </div> 
                            <ul className="contact-list-2 pt-5">
                                <li>
                                    <a href={"tel:"+card_detail?.landline} style={{color: "#003366"}}>
                                        <i aria-hidden="true" className="fas fa-phone-alt"></i>
                                        {card_detail?.landline}
                                        </a>
                                    </li> 
                                    <li>
                                        <a href={"tel:"+card_detail?.phone} style={{color: "#003366"}}>
                                        <i aria-hidden="true" className="fas fa-mobile-alt"></i>
                                        {card_detail?.phone}
                                        </a>
                                    </li> 
                                    <li>
                                        <a href={"mailto:"+card_detail?.email} target="_top" style={{color: "#003366"}}>
                                        <i aria-hidden="true" className="fas fa-envelope"></i>
                                        {card_detail?.email}
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="#" style={{color: "#003366"}}>
                                        <i aria-hidden="true" className="fas fa-briefcase"></i>
                                        {card_detail?.designation}
                                        </a>
                                    </li> 
                                    <li>
                                        <a href={card_detail?.hosp_url} target="_blank" style={{color: "#003366"}}>
                                            <i aria-hidden="true" className="fas fa-globe"></i>
                                            {"Visit Profile"}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={card_detail?.hosp_url} target="_blank" style={{color: "#003366"}}>
                                            <i aria-hidden="true" className="fas fa-comment-dots"></i>
                                            Write a review
                                        </a>
                                    </li>
                            </ul> 
                            <ul className="social-list-2 text-left">
                                <li>
                                    <a href={"https://api.whatsapp.com/send?phone="+card_detail?.whatsapp?.replace(/\s/g, '')+""} 
                                    target="_blank" aria-hidden="true" className="fab fa-whatsapp">
                                    </a>
                                </li> 
                                <li>
                                    <a className="linkedinicon" title="Linkedin" href="#">
                                    <i className="fab fa-linkedin-in" />
                                    
                                    </a>
                                </li> 
                                <li>
                                    <a href="#" title="Facebook" className="ico-facebook">
                                    <i className="fab fa-facebook-f" />
                                    </a>
                                </li> 
                                <li>
                                    <a className="googleicon" title="Instagram" href="#">
                                        <i className="fab fa-instagram" />
                                        
                                    </a>
                                </li>
                            </ul>
                            <div className="social-list-2 text-left">
                                <a href="https://sehalife.com/" style={{color:"gray !important"}}>Power by sehalife</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="scaner" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{maxWidth:"90% !important"}}>
                    <div className="modal-content">
                        <div className="modal-header border-bottom p-3">
                            <h5 className="modal-title" id="speciality_heading">Scan Me</h5>
                            <button onClick={(e)=>HideModal(e)} className="btn-close"></button>
                        </div>
                        <div className="modal-body p-3 pt-4" id="update_center_modal_body">
                            <div className="row">
                                <div className="col-12 text-center">
                                <img src={card_detail?.qr} style={{width:"30%"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CenterContent;
